import {
	getServerPageCategoryItems,
	getServerPageItem,
	getServerPageItemQuickSearch,
	getServerPageItems,
	getServerPageItemsByCollection,
} from "@/data/__generated__/api/ssr";
import {
	useCategoryItemsQuery,
	useItemQuery,
	useItemsByCollectionQuery,
	useItemsByManufacturerQuery,
	useItemsQuery,
} from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const ProductRepo = {
	Items: RepoBuilder.createClientRequest({
		hook: useItemsQuery,
	}),

	ItemsAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageItems,
	}),

	Product: RepoBuilder.createClientRequest({
		hook: useItemQuery,
	}),

	ProductsByCollection: RepoBuilder.createClientRequest({
		hook: useItemsByCollectionQuery,
	}),

	ProductsByManufacturer: RepoBuilder.createClientRequest({
		hook: useItemsByManufacturerQuery,
	}),

	ProductAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageItem,
	}),

	ProductsByCollectionAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageItemsByCollection,
	}),

	CategoryProducts: RepoBuilder.createClientRequest({
		hook: useCategoryItemsQuery,
	}),

	CategoryProductsAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageCategoryItems,
	}),

	ItemQuickSearchAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageItemQuickSearch,
	}),
};

export { ProductRepo };
