import { createApi } from "../helpers/createApi";
import {
	ArticlesRepo,
	CategoryRepo,
	CollectionRepo,
	CountryRepo,
	LinkRepo,
	ManufacturerRepo,
	OptionRepo,
	PageRepo,
	PaymentRepo,
	ProductRepo,
	PropertiesRepo,
	RedirectRepo,
	UserRepo,
} from "../repository";
import { DiscountRequestRepo } from "../repository/discount_request";
import { OrderRepo } from "../repository/order";

const repositories = {
	articles: ArticlesRepo,
	category: CategoryRepo,
	manufacturers: ManufacturerRepo,
	page: PageRepo,
	properties: PropertiesRepo,
	collection: CollectionRepo,
	country: CountryRepo,
	links: LinkRepo,
	products: ProductRepo,
	discountRequest: DiscountRequestRepo,
	order: OrderRepo,
	payment: PaymentRepo,
	option: OptionRepo,
	user: UserRepo,
	redirects: RedirectRepo,
};

const api = createApi(repositories);

export type TApiLayer = typeof api;

export default api;
