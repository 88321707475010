import * as Types from './types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CreateDiscountRequestDocument = gql`
    mutation CreateDiscountRequest($input: DiscountRequestInput!) {
  createDiscountRequest(input: $input) {
    requestId
  }
}
    `;
export type CreateDiscountRequestMutationFn = Apollo.MutationFunction<Types.CreateDiscountRequestMutation, Types.CreateDiscountRequestMutationVariables>;

/**
 * __useCreateDiscountRequestMutation__
 *
 * To run a mutation, you first call `useCreateDiscountRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscountRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiscountRequestMutation, { data, loading, error }] = useCreateDiscountRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDiscountRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDiscountRequestMutation, Types.CreateDiscountRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDiscountRequestMutation, Types.CreateDiscountRequestMutationVariables>(CreateDiscountRequestDocument, options);
      }
export type CreateDiscountRequestMutationHookResult = ReturnType<typeof useCreateDiscountRequestMutation>;
export type CreateDiscountRequestMutationResult = Apollo.MutationResult<Types.CreateDiscountRequestMutation>;
export type CreateDiscountRequestMutationOptions = Apollo.BaseMutationOptions<Types.CreateDiscountRequestMutation, Types.CreateDiscountRequestMutationVariables>;
export const MutationDocument = gql`
    mutation Mutation {
  dummy
}
    `;
export type MutationMutationFn = Apollo.MutationFunction<Types.MutationMutation, Types.MutationMutationVariables>;

/**
 * __useMutationMutation__
 *
 * To run a mutation, you first call `useMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationMutation, { data, loading, error }] = useMutationMutation({
 *   variables: {
 *   },
 * });
 */
export function useMutationMutation(baseOptions?: Apollo.MutationHookOptions<Types.MutationMutation, Types.MutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MutationMutation, Types.MutationMutationVariables>(MutationDocument, options);
      }
export type MutationMutationHookResult = ReturnType<typeof useMutationMutation>;
export type MutationMutationResult = Apollo.MutationResult<Types.MutationMutation>;
export type MutationMutationOptions = Apollo.BaseMutationOptions<Types.MutationMutation, Types.MutationMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($input: CreateOrderInput!) {
  createOrder(input: $input) {
    hash
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<Types.CreateOrderMutation, Types.CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateOrderMutation, Types.CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateOrderMutation, Types.CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<Types.CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<Types.CreateOrderMutation, Types.CreateOrderMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    accessToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables>;
export const ArticlesDocument = gql`
    query Articles {
  articles {
    content
    id
    image
    metaDescription
    metaTitle
    slug
    title
  }
}
    `;

/**
 * __useArticlesQuery__
 *
 * To run a query within a React component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticlesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ArticlesQuery, Types.ArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ArticlesQuery, Types.ArticlesQueryVariables>(ArticlesDocument, options);
      }
export function useArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ArticlesQuery, Types.ArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ArticlesQuery, Types.ArticlesQueryVariables>(ArticlesDocument, options);
        }
export function useArticlesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ArticlesQuery, Types.ArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ArticlesQuery, Types.ArticlesQueryVariables>(ArticlesDocument, options);
        }
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesSuspenseQueryHookResult = ReturnType<typeof useArticlesSuspenseQuery>;
export type ArticlesQueryResult = Apollo.QueryResult<Types.ArticlesQuery, Types.ArticlesQueryVariables>;
export function refetchArticlesQuery(variables?: Types.ArticlesQueryVariables) {
      return { query: ArticlesDocument, variables: variables }
    }
export const ArticleDocument = gql`
    query Article($input: ArticleInput!) {
  article(input: $input) {
    content
    id
    image
    metaDescription
    metaTitle
    slug
    title
  }
}
    `;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleQuery(baseOptions: Apollo.QueryHookOptions<Types.ArticleQuery, Types.ArticleQueryVariables> & ({ variables: Types.ArticleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ArticleQuery, Types.ArticleQueryVariables>(ArticleDocument, options);
      }
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ArticleQuery, Types.ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ArticleQuery, Types.ArticleQueryVariables>(ArticleDocument, options);
        }
export function useArticleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ArticleQuery, Types.ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ArticleQuery, Types.ArticleQueryVariables>(ArticleDocument, options);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleSuspenseQueryHookResult = ReturnType<typeof useArticleSuspenseQuery>;
export type ArticleQueryResult = Apollo.QueryResult<Types.ArticleQuery, Types.ArticleQueryVariables>;
export function refetchArticleQuery(variables: Types.ArticleQueryVariables) {
      return { query: ArticleDocument, variables: variables }
    }
export const CategoriesDocument = gql`
    query Categories {
  categories {
    childCategories {
      id
      slug
      title
    }
    id
    slug
    title
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CategoriesQuery, Types.CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CategoriesQuery, Types.CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CategoriesQuery, Types.CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CategoriesQuery, Types.CategoriesQueryVariables>(CategoriesDocument, options);
        }
export function useCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CategoriesQuery, Types.CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CategoriesQuery, Types.CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesSuspenseQueryHookResult = ReturnType<typeof useCategoriesSuspenseQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<Types.CategoriesQuery, Types.CategoriesQueryVariables>;
export function refetchCategoriesQuery(variables?: Types.CategoriesQueryVariables) {
      return { query: CategoriesDocument, variables: variables }
    }
export const ActiveCategoryDocument = gql`
    query ActiveCategory($input: ActiveCategoryInput!) {
  activeCategory(input: $input) {
    description
    haveChildrens
    header
    id
    metaDescription
    metaTitle
    title
    slug
    lft
    rgt
  }
}
    `;

/**
 * __useActiveCategoryQuery__
 *
 * To run a query within a React component, call `useActiveCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveCategoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActiveCategoryQuery(baseOptions: Apollo.QueryHookOptions<Types.ActiveCategoryQuery, Types.ActiveCategoryQueryVariables> & ({ variables: Types.ActiveCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActiveCategoryQuery, Types.ActiveCategoryQueryVariables>(ActiveCategoryDocument, options);
      }
export function useActiveCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActiveCategoryQuery, Types.ActiveCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActiveCategoryQuery, Types.ActiveCategoryQueryVariables>(ActiveCategoryDocument, options);
        }
export function useActiveCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ActiveCategoryQuery, Types.ActiveCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ActiveCategoryQuery, Types.ActiveCategoryQueryVariables>(ActiveCategoryDocument, options);
        }
export type ActiveCategoryQueryHookResult = ReturnType<typeof useActiveCategoryQuery>;
export type ActiveCategoryLazyQueryHookResult = ReturnType<typeof useActiveCategoryLazyQuery>;
export type ActiveCategorySuspenseQueryHookResult = ReturnType<typeof useActiveCategorySuspenseQuery>;
export type ActiveCategoryQueryResult = Apollo.QueryResult<Types.ActiveCategoryQuery, Types.ActiveCategoryQueryVariables>;
export function refetchActiveCategoryQuery(variables: Types.ActiveCategoryQueryVariables) {
      return { query: ActiveCategoryDocument, variables: variables }
    }
export const CollectionsDocument = gql`
    query Collections($input: ListCollectionsInput!) {
  collections(input: $input) {
    nextPage
    collections {
      countrySlug
      countryTitle
      id
      image
      labels {
        title
      }
      manufacturerSlug
      manufacturerTitle
      options {
        minPrice
        sizes
        thumbnails
      }
      slug
      title
    }
  }
}
    `;

/**
 * __useCollectionsQuery__
 *
 * To run a query within a React component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectionsQuery(baseOptions: Apollo.QueryHookOptions<Types.CollectionsQuery, Types.CollectionsQueryVariables> & ({ variables: Types.CollectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollectionsQuery, Types.CollectionsQueryVariables>(CollectionsDocument, options);
      }
export function useCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollectionsQuery, Types.CollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollectionsQuery, Types.CollectionsQueryVariables>(CollectionsDocument, options);
        }
export function useCollectionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CollectionsQuery, Types.CollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CollectionsQuery, Types.CollectionsQueryVariables>(CollectionsDocument, options);
        }
export type CollectionsQueryHookResult = ReturnType<typeof useCollectionsQuery>;
export type CollectionsLazyQueryHookResult = ReturnType<typeof useCollectionsLazyQuery>;
export type CollectionsSuspenseQueryHookResult = ReturnType<typeof useCollectionsSuspenseQuery>;
export type CollectionsQueryResult = Apollo.QueryResult<Types.CollectionsQuery, Types.CollectionsQueryVariables>;
export function refetchCollectionsQuery(variables: Types.CollectionsQueryVariables) {
      return { query: CollectionsDocument, variables: variables }
    }
export const CollectionDocument = gql`
    query Collection($input: CollectionInput!) {
  collection(input: $input) {
    countrySlug
    countryTitle
    id
    image
    labels {
      title
    }
    manufacturerSlug
    manufacturerTitle
    metaDescription
    metaTitle
    options {
      minPrice
      sizes
      thumbnails
    }
    pdf
    slug
    title
    video
    description
    collectionImages {
      filename
    }
  }
}
    `;

/**
 * __useCollectionQuery__
 *
 * To run a query within a React component, call `useCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectionQuery(baseOptions: Apollo.QueryHookOptions<Types.CollectionQuery, Types.CollectionQueryVariables> & ({ variables: Types.CollectionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollectionQuery, Types.CollectionQueryVariables>(CollectionDocument, options);
      }
export function useCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollectionQuery, Types.CollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollectionQuery, Types.CollectionQueryVariables>(CollectionDocument, options);
        }
export function useCollectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CollectionQuery, Types.CollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CollectionQuery, Types.CollectionQueryVariables>(CollectionDocument, options);
        }
export type CollectionQueryHookResult = ReturnType<typeof useCollectionQuery>;
export type CollectionLazyQueryHookResult = ReturnType<typeof useCollectionLazyQuery>;
export type CollectionSuspenseQueryHookResult = ReturnType<typeof useCollectionSuspenseQuery>;
export type CollectionQueryResult = Apollo.QueryResult<Types.CollectionQuery, Types.CollectionQueryVariables>;
export function refetchCollectionQuery(variables: Types.CollectionQueryVariables) {
      return { query: CollectionDocument, variables: variables }
    }
export const CollectionsByLabelDocument = gql`
    query CollectionsByLabel($input: CollectionsByLabelInput!) {
  collectionsByLabel(input: $input) {
    countrySlug
    countryTitle
    id
    image
    labels {
      title
    }
    manufacturerSlug
    manufacturerTitle
    options {
      minPrice
      sizes
      thumbnails
    }
    slug
    title
  }
}
    `;

/**
 * __useCollectionsByLabelQuery__
 *
 * To run a query within a React component, call `useCollectionsByLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsByLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsByLabelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectionsByLabelQuery(baseOptions: Apollo.QueryHookOptions<Types.CollectionsByLabelQuery, Types.CollectionsByLabelQueryVariables> & ({ variables: Types.CollectionsByLabelQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollectionsByLabelQuery, Types.CollectionsByLabelQueryVariables>(CollectionsByLabelDocument, options);
      }
export function useCollectionsByLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollectionsByLabelQuery, Types.CollectionsByLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollectionsByLabelQuery, Types.CollectionsByLabelQueryVariables>(CollectionsByLabelDocument, options);
        }
export function useCollectionsByLabelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CollectionsByLabelQuery, Types.CollectionsByLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CollectionsByLabelQuery, Types.CollectionsByLabelQueryVariables>(CollectionsByLabelDocument, options);
        }
export type CollectionsByLabelQueryHookResult = ReturnType<typeof useCollectionsByLabelQuery>;
export type CollectionsByLabelLazyQueryHookResult = ReturnType<typeof useCollectionsByLabelLazyQuery>;
export type CollectionsByLabelSuspenseQueryHookResult = ReturnType<typeof useCollectionsByLabelSuspenseQuery>;
export type CollectionsByLabelQueryResult = Apollo.QueryResult<Types.CollectionsByLabelQuery, Types.CollectionsByLabelQueryVariables>;
export function refetchCollectionsByLabelQuery(variables: Types.CollectionsByLabelQueryVariables) {
      return { query: CollectionsByLabelDocument, variables: variables }
    }
export const CollectionsByManufacturerDocument = gql`
    query CollectionsByManufacturer($input: CollectionsByManufacturerInput!) {
  collectionsByManufacturer(input: $input) {
    collections {
      countrySlug
      countryTitle
      id
      image
      labels {
        title
      }
      manufacturerSlug
      manufacturerTitle
      options {
        minPrice
        sizes
        thumbnails
      }
      slug
      title
    }
    nextPage
  }
}
    `;

/**
 * __useCollectionsByManufacturerQuery__
 *
 * To run a query within a React component, call `useCollectionsByManufacturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsByManufacturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsByManufacturerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectionsByManufacturerQuery(baseOptions: Apollo.QueryHookOptions<Types.CollectionsByManufacturerQuery, Types.CollectionsByManufacturerQueryVariables> & ({ variables: Types.CollectionsByManufacturerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollectionsByManufacturerQuery, Types.CollectionsByManufacturerQueryVariables>(CollectionsByManufacturerDocument, options);
      }
export function useCollectionsByManufacturerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollectionsByManufacturerQuery, Types.CollectionsByManufacturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollectionsByManufacturerQuery, Types.CollectionsByManufacturerQueryVariables>(CollectionsByManufacturerDocument, options);
        }
export function useCollectionsByManufacturerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CollectionsByManufacturerQuery, Types.CollectionsByManufacturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CollectionsByManufacturerQuery, Types.CollectionsByManufacturerQueryVariables>(CollectionsByManufacturerDocument, options);
        }
export type CollectionsByManufacturerQueryHookResult = ReturnType<typeof useCollectionsByManufacturerQuery>;
export type CollectionsByManufacturerLazyQueryHookResult = ReturnType<typeof useCollectionsByManufacturerLazyQuery>;
export type CollectionsByManufacturerSuspenseQueryHookResult = ReturnType<typeof useCollectionsByManufacturerSuspenseQuery>;
export type CollectionsByManufacturerQueryResult = Apollo.QueryResult<Types.CollectionsByManufacturerQuery, Types.CollectionsByManufacturerQueryVariables>;
export function refetchCollectionsByManufacturerQuery(variables: Types.CollectionsByManufacturerQueryVariables) {
      return { query: CollectionsByManufacturerDocument, variables: variables }
    }
export const NewCollectionsDocument = gql`
    query NewCollections($input: NewCollectionsInput!) {
  newCollections(input: $input) {
    countrySlug
    countryTitle
    id
    image
    labels {
      title
    }
    manufacturerSlug
    manufacturerTitle
    options {
      minPrice
      sizes
      thumbnails
    }
    slug
    title
  }
}
    `;

/**
 * __useNewCollectionsQuery__
 *
 * To run a query within a React component, call `useNewCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCollectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewCollectionsQuery(baseOptions: Apollo.QueryHookOptions<Types.NewCollectionsQuery, Types.NewCollectionsQueryVariables> & ({ variables: Types.NewCollectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NewCollectionsQuery, Types.NewCollectionsQueryVariables>(NewCollectionsDocument, options);
      }
export function useNewCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NewCollectionsQuery, Types.NewCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NewCollectionsQuery, Types.NewCollectionsQueryVariables>(NewCollectionsDocument, options);
        }
export function useNewCollectionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.NewCollectionsQuery, Types.NewCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.NewCollectionsQuery, Types.NewCollectionsQueryVariables>(NewCollectionsDocument, options);
        }
export type NewCollectionsQueryHookResult = ReturnType<typeof useNewCollectionsQuery>;
export type NewCollectionsLazyQueryHookResult = ReturnType<typeof useNewCollectionsLazyQuery>;
export type NewCollectionsSuspenseQueryHookResult = ReturnType<typeof useNewCollectionsSuspenseQuery>;
export type NewCollectionsQueryResult = Apollo.QueryResult<Types.NewCollectionsQuery, Types.NewCollectionsQueryVariables>;
export function refetchNewCollectionsQuery(variables: Types.NewCollectionsQueryVariables) {
      return { query: NewCollectionsDocument, variables: variables }
    }
export const SliderCollectionsDocument = gql`
    query SliderCollections($input: SliderCollectionsInput!) {
  sliderCollections(input: $input) {
    countryTitle
    id
    manufacturerTitle
    slug
    title
    image
  }
}
    `;

/**
 * __useSliderCollectionsQuery__
 *
 * To run a query within a React component, call `useSliderCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSliderCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSliderCollectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSliderCollectionsQuery(baseOptions: Apollo.QueryHookOptions<Types.SliderCollectionsQuery, Types.SliderCollectionsQueryVariables> & ({ variables: Types.SliderCollectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SliderCollectionsQuery, Types.SliderCollectionsQueryVariables>(SliderCollectionsDocument, options);
      }
export function useSliderCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SliderCollectionsQuery, Types.SliderCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SliderCollectionsQuery, Types.SliderCollectionsQueryVariables>(SliderCollectionsDocument, options);
        }
export function useSliderCollectionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SliderCollectionsQuery, Types.SliderCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SliderCollectionsQuery, Types.SliderCollectionsQueryVariables>(SliderCollectionsDocument, options);
        }
export type SliderCollectionsQueryHookResult = ReturnType<typeof useSliderCollectionsQuery>;
export type SliderCollectionsLazyQueryHookResult = ReturnType<typeof useSliderCollectionsLazyQuery>;
export type SliderCollectionsSuspenseQueryHookResult = ReturnType<typeof useSliderCollectionsSuspenseQuery>;
export type SliderCollectionsQueryResult = Apollo.QueryResult<Types.SliderCollectionsQuery, Types.SliderCollectionsQueryVariables>;
export function refetchSliderCollectionsQuery(variables: Types.SliderCollectionsQueryVariables) {
      return { query: SliderCollectionsDocument, variables: variables }
    }
export const CountriesDocument = gql`
    query Countries {
  countries {
    description
    header
    id
    metaDescription
    metaTitle
    slug
    title
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesQuery, Types.CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesQuery, Types.CountriesQueryVariables>(CountriesDocument, options);
        }
export function useCountriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountriesQuery, Types.CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesSuspenseQueryHookResult = ReturnType<typeof useCountriesSuspenseQuery>;
export type CountriesQueryResult = Apollo.QueryResult<Types.CountriesQuery, Types.CountriesQueryVariables>;
export function refetchCountriesQuery(variables?: Types.CountriesQueryVariables) {
      return { query: CountriesDocument, variables: variables }
    }
export const CategoryFiltersDocument = gql`
    query CategoryFilters($input: CategoryFiltersInput!) {
  categoryFilters(input: $input) {
    id
    title
    values {
      slug
      title
    }
  }
}
    `;

/**
 * __useCategoryFiltersQuery__
 *
 * To run a query within a React component, call `useCategoryFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryFiltersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCategoryFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.CategoryFiltersQuery, Types.CategoryFiltersQueryVariables> & ({ variables: Types.CategoryFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CategoryFiltersQuery, Types.CategoryFiltersQueryVariables>(CategoryFiltersDocument, options);
      }
export function useCategoryFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CategoryFiltersQuery, Types.CategoryFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CategoryFiltersQuery, Types.CategoryFiltersQueryVariables>(CategoryFiltersDocument, options);
        }
export function useCategoryFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CategoryFiltersQuery, Types.CategoryFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CategoryFiltersQuery, Types.CategoryFiltersQueryVariables>(CategoryFiltersDocument, options);
        }
export type CategoryFiltersQueryHookResult = ReturnType<typeof useCategoryFiltersQuery>;
export type CategoryFiltersLazyQueryHookResult = ReturnType<typeof useCategoryFiltersLazyQuery>;
export type CategoryFiltersSuspenseQueryHookResult = ReturnType<typeof useCategoryFiltersSuspenseQuery>;
export type CategoryFiltersQueryResult = Apollo.QueryResult<Types.CategoryFiltersQuery, Types.CategoryFiltersQueryVariables>;
export function refetchCategoryFiltersQuery(variables: Types.CategoryFiltersQueryVariables) {
      return { query: CategoryFiltersDocument, variables: variables }
    }
export const FiltersByManufacturerDocument = gql`
    query FiltersByManufacturer($input: FiltersByManufacturerInput!) {
  filtersByManufacturer(input: $input) {
    id
    title
    values {
      slug
      title
    }
  }
}
    `;

/**
 * __useFiltersByManufacturerQuery__
 *
 * To run a query within a React component, call `useFiltersByManufacturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFiltersByManufacturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFiltersByManufacturerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFiltersByManufacturerQuery(baseOptions: Apollo.QueryHookOptions<Types.FiltersByManufacturerQuery, Types.FiltersByManufacturerQueryVariables> & ({ variables: Types.FiltersByManufacturerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FiltersByManufacturerQuery, Types.FiltersByManufacturerQueryVariables>(FiltersByManufacturerDocument, options);
      }
export function useFiltersByManufacturerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FiltersByManufacturerQuery, Types.FiltersByManufacturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FiltersByManufacturerQuery, Types.FiltersByManufacturerQueryVariables>(FiltersByManufacturerDocument, options);
        }
export function useFiltersByManufacturerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FiltersByManufacturerQuery, Types.FiltersByManufacturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FiltersByManufacturerQuery, Types.FiltersByManufacturerQueryVariables>(FiltersByManufacturerDocument, options);
        }
export type FiltersByManufacturerQueryHookResult = ReturnType<typeof useFiltersByManufacturerQuery>;
export type FiltersByManufacturerLazyQueryHookResult = ReturnType<typeof useFiltersByManufacturerLazyQuery>;
export type FiltersByManufacturerSuspenseQueryHookResult = ReturnType<typeof useFiltersByManufacturerSuspenseQuery>;
export type FiltersByManufacturerQueryResult = Apollo.QueryResult<Types.FiltersByManufacturerQuery, Types.FiltersByManufacturerQueryVariables>;
export function refetchFiltersByManufacturerQuery(variables: Types.FiltersByManufacturerQueryVariables) {
      return { query: FiltersByManufacturerDocument, variables: variables }
    }
export const LinksDocument = gql`
    query Links {
  links {
    id
    image
    title
    url
  }
}
    `;

/**
 * __useLinksQuery__
 *
 * To run a query within a React component, call `useLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinksQuery(baseOptions?: Apollo.QueryHookOptions<Types.LinksQuery, Types.LinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LinksQuery, Types.LinksQueryVariables>(LinksDocument, options);
      }
export function useLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LinksQuery, Types.LinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LinksQuery, Types.LinksQueryVariables>(LinksDocument, options);
        }
export function useLinksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LinksQuery, Types.LinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LinksQuery, Types.LinksQueryVariables>(LinksDocument, options);
        }
export type LinksQueryHookResult = ReturnType<typeof useLinksQuery>;
export type LinksLazyQueryHookResult = ReturnType<typeof useLinksLazyQuery>;
export type LinksSuspenseQueryHookResult = ReturnType<typeof useLinksSuspenseQuery>;
export type LinksQueryResult = Apollo.QueryResult<Types.LinksQuery, Types.LinksQueryVariables>;
export function refetchLinksQuery(variables?: Types.LinksQueryVariables) {
      return { query: LinksDocument, variables: variables }
    }
export const ManufacturersGroupsDocument = gql`
    query ManufacturersGroups {
  manufacturersGroups {
    key
    manufacturers {
      slug
      title
    }
  }
}
    `;

/**
 * __useManufacturersGroupsQuery__
 *
 * To run a query within a React component, call `useManufacturersGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturersGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturersGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useManufacturersGroupsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ManufacturersGroupsQuery, Types.ManufacturersGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ManufacturersGroupsQuery, Types.ManufacturersGroupsQueryVariables>(ManufacturersGroupsDocument, options);
      }
export function useManufacturersGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ManufacturersGroupsQuery, Types.ManufacturersGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ManufacturersGroupsQuery, Types.ManufacturersGroupsQueryVariables>(ManufacturersGroupsDocument, options);
        }
export function useManufacturersGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ManufacturersGroupsQuery, Types.ManufacturersGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ManufacturersGroupsQuery, Types.ManufacturersGroupsQueryVariables>(ManufacturersGroupsDocument, options);
        }
export type ManufacturersGroupsQueryHookResult = ReturnType<typeof useManufacturersGroupsQuery>;
export type ManufacturersGroupsLazyQueryHookResult = ReturnType<typeof useManufacturersGroupsLazyQuery>;
export type ManufacturersGroupsSuspenseQueryHookResult = ReturnType<typeof useManufacturersGroupsSuspenseQuery>;
export type ManufacturersGroupsQueryResult = Apollo.QueryResult<Types.ManufacturersGroupsQuery, Types.ManufacturersGroupsQueryVariables>;
export function refetchManufacturersGroupsQuery(variables?: Types.ManufacturersGroupsQueryVariables) {
      return { query: ManufacturersGroupsDocument, variables: variables }
    }
export const ManufacturerDocument = gql`
    query Manufacturer($input: ManufacturerInput!) {
  manufacturer(input: $input) {
    countrySlug
    countryTitle
    description
    header
    id
    logo
    metaDescription
    metaTitle
    slug
    title
    video
  }
}
    `;

/**
 * __useManufacturerQuery__
 *
 * To run a query within a React component, call `useManufacturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManufacturerQuery(baseOptions: Apollo.QueryHookOptions<Types.ManufacturerQuery, Types.ManufacturerQueryVariables> & ({ variables: Types.ManufacturerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ManufacturerQuery, Types.ManufacturerQueryVariables>(ManufacturerDocument, options);
      }
export function useManufacturerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ManufacturerQuery, Types.ManufacturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ManufacturerQuery, Types.ManufacturerQueryVariables>(ManufacturerDocument, options);
        }
export function useManufacturerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ManufacturerQuery, Types.ManufacturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ManufacturerQuery, Types.ManufacturerQueryVariables>(ManufacturerDocument, options);
        }
export type ManufacturerQueryHookResult = ReturnType<typeof useManufacturerQuery>;
export type ManufacturerLazyQueryHookResult = ReturnType<typeof useManufacturerLazyQuery>;
export type ManufacturerSuspenseQueryHookResult = ReturnType<typeof useManufacturerSuspenseQuery>;
export type ManufacturerQueryResult = Apollo.QueryResult<Types.ManufacturerQuery, Types.ManufacturerQueryVariables>;
export function refetchManufacturerQuery(variables: Types.ManufacturerQueryVariables) {
      return { query: ManufacturerDocument, variables: variables }
    }
export const ManufacturersDocument = gql`
    query Manufacturers($input: ManufacturersInput!) {
  manufacturers(input: $input) {
    manufacturerList {
      countryTitle
      id
      logo
      priceFrom
      slug
      title
    }
    nextPage
    totalCount
  }
}
    `;

/**
 * __useManufacturersQuery__
 *
 * To run a query within a React component, call `useManufacturersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManufacturersQuery(baseOptions: Apollo.QueryHookOptions<Types.ManufacturersQuery, Types.ManufacturersQueryVariables> & ({ variables: Types.ManufacturersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ManufacturersQuery, Types.ManufacturersQueryVariables>(ManufacturersDocument, options);
      }
export function useManufacturersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ManufacturersQuery, Types.ManufacturersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ManufacturersQuery, Types.ManufacturersQueryVariables>(ManufacturersDocument, options);
        }
export function useManufacturersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ManufacturersQuery, Types.ManufacturersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ManufacturersQuery, Types.ManufacturersQueryVariables>(ManufacturersDocument, options);
        }
export type ManufacturersQueryHookResult = ReturnType<typeof useManufacturersQuery>;
export type ManufacturersLazyQueryHookResult = ReturnType<typeof useManufacturersLazyQuery>;
export type ManufacturersSuspenseQueryHookResult = ReturnType<typeof useManufacturersSuspenseQuery>;
export type ManufacturersQueryResult = Apollo.QueryResult<Types.ManufacturersQuery, Types.ManufacturersQueryVariables>;
export function refetchManufacturersQuery(variables: Types.ManufacturersQueryVariables) {
      return { query: ManufacturersDocument, variables: variables }
    }
export const OrderDocument = gql`
    query Order($input: OrderInput!) {
  order(input: $input) {
    comment
    customer {
      email
      id
      name
      phone
    }
    hash
    id
    status
    created
    updated
    delivery
    discount
    orderItems {
      amount
      categorySlug
      collectionSlug
      itemHeader
      itemId
      itemImage
      itemPrice
      itemTitle
      price
      unitTitle
    }
    pin
    total
  }
}
    `;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.OrderQuery, Types.OrderQueryVariables> & ({ variables: Types.OrderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrderQuery, Types.OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrderQuery, Types.OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrderQuery, Types.OrderQueryVariables>(OrderDocument, options);
        }
export function useOrderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.OrderQuery, Types.OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.OrderQuery, Types.OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderSuspenseQueryHookResult = ReturnType<typeof useOrderSuspenseQuery>;
export type OrderQueryResult = Apollo.QueryResult<Types.OrderQuery, Types.OrderQueryVariables>;
export function refetchOrderQuery(variables: Types.OrderQueryVariables) {
      return { query: OrderDocument, variables: variables }
    }
export const PageDocument = gql`
    query Page($input: PageInput!) {
  page(input: $input) {
    content
    header
    id
    metaDescription
    metaTitle
    slug
    title
  }
}
    `;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePageQuery(baseOptions: Apollo.QueryHookOptions<Types.PageQuery, Types.PageQueryVariables> & ({ variables: Types.PageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PageQuery, Types.PageQueryVariables>(PageDocument, options);
      }
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PageQuery, Types.PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PageQuery, Types.PageQueryVariables>(PageDocument, options);
        }
export function usePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PageQuery, Types.PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PageQuery, Types.PageQueryVariables>(PageDocument, options);
        }
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageSuspenseQueryHookResult = ReturnType<typeof usePageSuspenseQuery>;
export type PageQueryResult = Apollo.QueryResult<Types.PageQuery, Types.PageQueryVariables>;
export function refetchPageQuery(variables: Types.PageQueryVariables) {
      return { query: PageDocument, variables: variables }
    }
export const SiteMessageDocument = gql`
    query SiteMessage {
  siteMessage
}
    `;

/**
 * __useSiteMessageQuery__
 *
 * To run a query within a React component, call `useSiteMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteMessageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSiteMessageQuery(baseOptions?: Apollo.QueryHookOptions<Types.SiteMessageQuery, Types.SiteMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SiteMessageQuery, Types.SiteMessageQueryVariables>(SiteMessageDocument, options);
      }
export function useSiteMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SiteMessageQuery, Types.SiteMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SiteMessageQuery, Types.SiteMessageQueryVariables>(SiteMessageDocument, options);
        }
export function useSiteMessageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SiteMessageQuery, Types.SiteMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SiteMessageQuery, Types.SiteMessageQueryVariables>(SiteMessageDocument, options);
        }
export type SiteMessageQueryHookResult = ReturnType<typeof useSiteMessageQuery>;
export type SiteMessageLazyQueryHookResult = ReturnType<typeof useSiteMessageLazyQuery>;
export type SiteMessageSuspenseQueryHookResult = ReturnType<typeof useSiteMessageSuspenseQuery>;
export type SiteMessageQueryResult = Apollo.QueryResult<Types.SiteMessageQuery, Types.SiteMessageQueryVariables>;
export function refetchSiteMessageQuery(variables?: Types.SiteMessageQueryVariables) {
      return { query: SiteMessageDocument, variables: variables }
    }
export const PageSeoDocument = gql`
    query PageSeo($input: PageSeoInput!) {
  pageSeo(input: $input) {
    metaDescription
    metaTitle
  }
}
    `;

/**
 * __usePageSeoQuery__
 *
 * To run a query within a React component, call `usePageSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageSeoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePageSeoQuery(baseOptions: Apollo.QueryHookOptions<Types.PageSeoQuery, Types.PageSeoQueryVariables> & ({ variables: Types.PageSeoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PageSeoQuery, Types.PageSeoQueryVariables>(PageSeoDocument, options);
      }
export function usePageSeoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PageSeoQuery, Types.PageSeoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PageSeoQuery, Types.PageSeoQueryVariables>(PageSeoDocument, options);
        }
export function usePageSeoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PageSeoQuery, Types.PageSeoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PageSeoQuery, Types.PageSeoQueryVariables>(PageSeoDocument, options);
        }
export type PageSeoQueryHookResult = ReturnType<typeof usePageSeoQuery>;
export type PageSeoLazyQueryHookResult = ReturnType<typeof usePageSeoLazyQuery>;
export type PageSeoSuspenseQueryHookResult = ReturnType<typeof usePageSeoSuspenseQuery>;
export type PageSeoQueryResult = Apollo.QueryResult<Types.PageSeoQuery, Types.PageSeoQueryVariables>;
export function refetchPageSeoQuery(variables: Types.PageSeoQueryVariables) {
      return { query: PageSeoDocument, variables: variables }
    }
export const PaymentDocument = gql`
    query Payment($input: PaymentInput!) {
  paymentUrl(input: $input) {
    url
  }
}
    `;

/**
 * __usePaymentQuery__
 *
 * To run a query within a React component, call `usePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentQuery(baseOptions: Apollo.QueryHookOptions<Types.PaymentQuery, Types.PaymentQueryVariables> & ({ variables: Types.PaymentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PaymentQuery, Types.PaymentQueryVariables>(PaymentDocument, options);
      }
export function usePaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PaymentQuery, Types.PaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PaymentQuery, Types.PaymentQueryVariables>(PaymentDocument, options);
        }
export function usePaymentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PaymentQuery, Types.PaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PaymentQuery, Types.PaymentQueryVariables>(PaymentDocument, options);
        }
export type PaymentQueryHookResult = ReturnType<typeof usePaymentQuery>;
export type PaymentLazyQueryHookResult = ReturnType<typeof usePaymentLazyQuery>;
export type PaymentSuspenseQueryHookResult = ReturnType<typeof usePaymentSuspenseQuery>;
export type PaymentQueryResult = Apollo.QueryResult<Types.PaymentQuery, Types.PaymentQueryVariables>;
export function refetchPaymentQuery(variables: Types.PaymentQueryVariables) {
      return { query: PaymentDocument, variables: variables }
    }
export const ItemsDocument = gql`
    query Items($input: ItemsInput!) {
  items(input: $input) {
    items {
      collectionSlug
      collectionTitle
      countrySlug
      countryTitle
      header
      id
      image
      manufacturerSlug
      manufacturerTitle
      order {
        minOrderPrice
        orderAmounts {
          key
          title
          value
        }
      }
      price
      properties {
        typeProperty
        typePropertyId
        typePropertySlug
        value
      }
      sellByUnit
      status
      title
      unitTitle
    }
    nextPage
  }
}
    `;

/**
 * __useItemsQuery__
 *
 * To run a query within a React component, call `useItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemsQuery(baseOptions: Apollo.QueryHookOptions<Types.ItemsQuery, Types.ItemsQueryVariables> & ({ variables: Types.ItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ItemsQuery, Types.ItemsQueryVariables>(ItemsDocument, options);
      }
export function useItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ItemsQuery, Types.ItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ItemsQuery, Types.ItemsQueryVariables>(ItemsDocument, options);
        }
export function useItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ItemsQuery, Types.ItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ItemsQuery, Types.ItemsQueryVariables>(ItemsDocument, options);
        }
export type ItemsQueryHookResult = ReturnType<typeof useItemsQuery>;
export type ItemsLazyQueryHookResult = ReturnType<typeof useItemsLazyQuery>;
export type ItemsSuspenseQueryHookResult = ReturnType<typeof useItemsSuspenseQuery>;
export type ItemsQueryResult = Apollo.QueryResult<Types.ItemsQuery, Types.ItemsQueryVariables>;
export function refetchItemsQuery(variables: Types.ItemsQueryVariables) {
      return { query: ItemsDocument, variables: variables }
    }
export const ItemDocument = gql`
    query Item($input: ItemByIdInput!) {
  item(input: $input) {
    collectionSlug
    collectionTitle
    countrySlug
    countryTitle
    categoryTitle
    categorySlug
    header
    description
    id
    image
    manufacturerSlug
    manufacturerTitle
    metaDescription
    providerTitle
    metaTitle
    itemImages {
      filename
      id
    }
    order {
      minOrderPrice
      orderAmounts {
        key
        title
        value
      }
    }
    pdf
    price
    properties {
      typeProperty
      typePropertyId
      typePropertySlug
      value
      valueSlug
    }
    sellByUnit
    status
    title
    unitTitle
    video
  }
}
    `;

/**
 * __useItemQuery__
 *
 * To run a query within a React component, call `useItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemQuery(baseOptions: Apollo.QueryHookOptions<Types.ItemQuery, Types.ItemQueryVariables> & ({ variables: Types.ItemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ItemQuery, Types.ItemQueryVariables>(ItemDocument, options);
      }
export function useItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ItemQuery, Types.ItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ItemQuery, Types.ItemQueryVariables>(ItemDocument, options);
        }
export function useItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ItemQuery, Types.ItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ItemQuery, Types.ItemQueryVariables>(ItemDocument, options);
        }
export type ItemQueryHookResult = ReturnType<typeof useItemQuery>;
export type ItemLazyQueryHookResult = ReturnType<typeof useItemLazyQuery>;
export type ItemSuspenseQueryHookResult = ReturnType<typeof useItemSuspenseQuery>;
export type ItemQueryResult = Apollo.QueryResult<Types.ItemQuery, Types.ItemQueryVariables>;
export function refetchItemQuery(variables: Types.ItemQueryVariables) {
      return { query: ItemDocument, variables: variables }
    }
export const CategoryItemsDocument = gql`
    query CategoryItems($input: ItemsByCategoryInput!) {
  categoryItems(input: $input) {
    items {
      collectionSlug
      collectionTitle
      countrySlug
      countryTitle
      categoryTitle
      categorySlug
      header
      id
      image
      manufacturerSlug
      manufacturerTitle
      order {
        minOrderPrice
        orderAmounts {
          key
          title
          value
        }
      }
      price
      properties {
        typeProperty
        typePropertyId
        typePropertySlug
        value
      }
      sellByUnit
      status
      title
      unitTitle
    }
    nextPage
  }
}
    `;

/**
 * __useCategoryItemsQuery__
 *
 * To run a query within a React component, call `useCategoryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCategoryItemsQuery(baseOptions: Apollo.QueryHookOptions<Types.CategoryItemsQuery, Types.CategoryItemsQueryVariables> & ({ variables: Types.CategoryItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CategoryItemsQuery, Types.CategoryItemsQueryVariables>(CategoryItemsDocument, options);
      }
export function useCategoryItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CategoryItemsQuery, Types.CategoryItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CategoryItemsQuery, Types.CategoryItemsQueryVariables>(CategoryItemsDocument, options);
        }
export function useCategoryItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CategoryItemsQuery, Types.CategoryItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CategoryItemsQuery, Types.CategoryItemsQueryVariables>(CategoryItemsDocument, options);
        }
export type CategoryItemsQueryHookResult = ReturnType<typeof useCategoryItemsQuery>;
export type CategoryItemsLazyQueryHookResult = ReturnType<typeof useCategoryItemsLazyQuery>;
export type CategoryItemsSuspenseQueryHookResult = ReturnType<typeof useCategoryItemsSuspenseQuery>;
export type CategoryItemsQueryResult = Apollo.QueryResult<Types.CategoryItemsQuery, Types.CategoryItemsQueryVariables>;
export function refetchCategoryItemsQuery(variables: Types.CategoryItemsQueryVariables) {
      return { query: CategoryItemsDocument, variables: variables }
    }
export const ItemsByCollectionDocument = gql`
    query ItemsByCollection($input: ItemsByCollectionInput!) {
  itemsByCollection(input: $input) {
    items {
      collectionSlug
      collectionTitle
      countrySlug
      countryTitle
      header
      id
      image
      manufacturerSlug
      manufacturerTitle
      order {
        minOrderPrice
        orderAmounts {
          key
          title
          value
        }
      }
      price
      properties {
        typeProperty
        typePropertyId
        typePropertySlug
        value
      }
      sellByUnit
      status
      title
      unitTitle
    }
    nextPage
  }
}
    `;

/**
 * __useItemsByCollectionQuery__
 *
 * To run a query within a React component, call `useItemsByCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsByCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsByCollectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemsByCollectionQuery(baseOptions: Apollo.QueryHookOptions<Types.ItemsByCollectionQuery, Types.ItemsByCollectionQueryVariables> & ({ variables: Types.ItemsByCollectionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ItemsByCollectionQuery, Types.ItemsByCollectionQueryVariables>(ItemsByCollectionDocument, options);
      }
export function useItemsByCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ItemsByCollectionQuery, Types.ItemsByCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ItemsByCollectionQuery, Types.ItemsByCollectionQueryVariables>(ItemsByCollectionDocument, options);
        }
export function useItemsByCollectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ItemsByCollectionQuery, Types.ItemsByCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ItemsByCollectionQuery, Types.ItemsByCollectionQueryVariables>(ItemsByCollectionDocument, options);
        }
export type ItemsByCollectionQueryHookResult = ReturnType<typeof useItemsByCollectionQuery>;
export type ItemsByCollectionLazyQueryHookResult = ReturnType<typeof useItemsByCollectionLazyQuery>;
export type ItemsByCollectionSuspenseQueryHookResult = ReturnType<typeof useItemsByCollectionSuspenseQuery>;
export type ItemsByCollectionQueryResult = Apollo.QueryResult<Types.ItemsByCollectionQuery, Types.ItemsByCollectionQueryVariables>;
export function refetchItemsByCollectionQuery(variables: Types.ItemsByCollectionQueryVariables) {
      return { query: ItemsByCollectionDocument, variables: variables }
    }
export const ItemsByManufacturerDocument = gql`
    query ItemsByManufacturer($input: ItemsByManufacturerInput!) {
  itemsByManufacturer(input: $input) {
    items {
      collectionSlug
      collectionTitle
      countrySlug
      countryTitle
      categoryTitle
      categorySlug
      header
      id
      image
      manufacturerSlug
      manufacturerTitle
      order {
        minOrderPrice
        orderAmounts {
          key
          title
          value
        }
      }
      price
      properties {
        typeProperty
        typePropertyId
        typePropertySlug
        value
      }
      sellByUnit
      status
      title
      unitTitle
    }
    nextPage
  }
}
    `;

/**
 * __useItemsByManufacturerQuery__
 *
 * To run a query within a React component, call `useItemsByManufacturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsByManufacturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsByManufacturerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemsByManufacturerQuery(baseOptions: Apollo.QueryHookOptions<Types.ItemsByManufacturerQuery, Types.ItemsByManufacturerQueryVariables> & ({ variables: Types.ItemsByManufacturerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ItemsByManufacturerQuery, Types.ItemsByManufacturerQueryVariables>(ItemsByManufacturerDocument, options);
      }
export function useItemsByManufacturerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ItemsByManufacturerQuery, Types.ItemsByManufacturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ItemsByManufacturerQuery, Types.ItemsByManufacturerQueryVariables>(ItemsByManufacturerDocument, options);
        }
export function useItemsByManufacturerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ItemsByManufacturerQuery, Types.ItemsByManufacturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ItemsByManufacturerQuery, Types.ItemsByManufacturerQueryVariables>(ItemsByManufacturerDocument, options);
        }
export type ItemsByManufacturerQueryHookResult = ReturnType<typeof useItemsByManufacturerQuery>;
export type ItemsByManufacturerLazyQueryHookResult = ReturnType<typeof useItemsByManufacturerLazyQuery>;
export type ItemsByManufacturerSuspenseQueryHookResult = ReturnType<typeof useItemsByManufacturerSuspenseQuery>;
export type ItemsByManufacturerQueryResult = Apollo.QueryResult<Types.ItemsByManufacturerQuery, Types.ItemsByManufacturerQueryVariables>;
export function refetchItemsByManufacturerQuery(variables: Types.ItemsByManufacturerQueryVariables) {
      return { query: ItemsByManufacturerDocument, variables: variables }
    }
export const ItemQuickSearchDocument = gql`
    query ItemQuickSearch($input: ItemByIdInput!) {
  item(input: $input) {
    collectionSlug
    categorySlug
    id
  }
}
    `;

/**
 * __useItemQuickSearchQuery__
 *
 * To run a query within a React component, call `useItemQuickSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemQuickSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemQuickSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemQuickSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.ItemQuickSearchQuery, Types.ItemQuickSearchQueryVariables> & ({ variables: Types.ItemQuickSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ItemQuickSearchQuery, Types.ItemQuickSearchQueryVariables>(ItemQuickSearchDocument, options);
      }
export function useItemQuickSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ItemQuickSearchQuery, Types.ItemQuickSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ItemQuickSearchQuery, Types.ItemQuickSearchQueryVariables>(ItemQuickSearchDocument, options);
        }
export function useItemQuickSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ItemQuickSearchQuery, Types.ItemQuickSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ItemQuickSearchQuery, Types.ItemQuickSearchQueryVariables>(ItemQuickSearchDocument, options);
        }
export type ItemQuickSearchQueryHookResult = ReturnType<typeof useItemQuickSearchQuery>;
export type ItemQuickSearchLazyQueryHookResult = ReturnType<typeof useItemQuickSearchLazyQuery>;
export type ItemQuickSearchSuspenseQueryHookResult = ReturnType<typeof useItemQuickSearchSuspenseQuery>;
export type ItemQuickSearchQueryResult = Apollo.QueryResult<Types.ItemQuickSearchQuery, Types.ItemQuickSearchQueryVariables>;
export function refetchItemQuickSearchQuery(variables: Types.ItemQuickSearchQueryVariables) {
      return { query: ItemQuickSearchDocument, variables: variables }
    }
export const RedirectsDocument = gql`
    query Redirects {
  redirects {
    fromUrl
    id
    toUrl
  }
}
    `;

/**
 * __useRedirectsQuery__
 *
 * To run a query within a React component, call `useRedirectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedirectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedirectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRedirectsQuery(baseOptions?: Apollo.QueryHookOptions<Types.RedirectsQuery, Types.RedirectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RedirectsQuery, Types.RedirectsQueryVariables>(RedirectsDocument, options);
      }
export function useRedirectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RedirectsQuery, Types.RedirectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RedirectsQuery, Types.RedirectsQueryVariables>(RedirectsDocument, options);
        }
export function useRedirectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RedirectsQuery, Types.RedirectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RedirectsQuery, Types.RedirectsQueryVariables>(RedirectsDocument, options);
        }
export type RedirectsQueryHookResult = ReturnType<typeof useRedirectsQuery>;
export type RedirectsLazyQueryHookResult = ReturnType<typeof useRedirectsLazyQuery>;
export type RedirectsSuspenseQueryHookResult = ReturnType<typeof useRedirectsSuspenseQuery>;
export type RedirectsQueryResult = Apollo.QueryResult<Types.RedirectsQuery, Types.RedirectsQueryVariables>;
export function refetchRedirectsQuery(variables?: Types.RedirectsQueryVariables) {
      return { query: RedirectsDocument, variables: variables }
    }